import React from "react"
import { Link } from "gatsby"

const Hero = () => {
  return (
    <section className="flex px-5 py-24 items-center justify-center flex-col text-white body-font hero-bg">
      <div className="container mx-auto text-center lg:w-2/3 w-full">
        <h1 className="sm:text-4xl lg:text-6xl text-3xl mb-16 font-medium text-white max-w-xl lg:max-w-6xl mx-auto capitalize font-bold hero-leading">
          With our tires we guarantee you a safe drive
        </h1>
        <div className="flex justify-center">
          <Link to="/#contact">
            <button className="inline-flex text-white bg-blue-800 border-0 py-3 px-6 focus:outline-none hover:bg-blue-900 rounded text-lg transition transition-hover duration-400 ease-in">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Hero
