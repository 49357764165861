import React from "react"
import useWindowSize from "../hooks/useWindowSize"
import { StaticImage } from "gatsby-plugin-image"

const Products = () => {
  const [width] = useWindowSize()

  let offsetOne = width > 1024 ? "600" : "100"
  let offsetTwo = width > 1024 ? "400" : "100"

  const contents = [
    {
      id: 1,
      img: (
        <StaticImage
          src="../images/products/pass.png"
          alt="Passenger Tires"
          width={150}
          placeholder="blurred"
          className="mx-auto mt-8"
        />
      ),
      header: "Passenger Tires",
      delay: "0",
      offset: offsetOne,
    },
    {
      id: 2,
      img: (
        <StaticImage
          src="../images/products/truck.png"
          alt="Truck Tires"
          width={100}
          placeholder="blurred"
          className="mx-auto mt-5"
        />
      ),
      header: "Truck Tires",
      delay: "200",
      offset: offsetOne,
    },
    {
      id: 3,
      img: (
        <StaticImage
          src="../images/products/offTheRoad.png"
          alt="OTR Tires"
          width={100}
          placeholder="blurred"
          className="mx-auto mt-5"
        />
      ),
      header: "Off Road Tires",
      delay: "400",
      offset: offsetOne,
    },
    {
      id: 4,
      img: (
        <StaticImage
          src="../images/products/material_handling.png"
          alt="Material Handling Tires"
          width={130}
          placeholder="blurred"
          className="mx-auto mt-5"
        />
      ),
      header: "Material Handling",
      delay: "600",
      offset: offsetTwo,
    },
    {
      id: 5,
      img: (
        <StaticImage
          src="../images/products/tube.png"
          alt="Tubes & Flaps"
          width={150}
          placeholder="blurred"
          className="mx-auto mt-5"
        />
      ),
      header: "Tubes & Flaps",
      delay: "800",
      offset: offsetTwo,
    },
    {
      id: 6,
      img: (
        <StaticImage
          src="../images/products/battery.png"
          alt="Battery"
          width={200}
          placeholder="blurred"
          className="mx-auto mt-5"
        />
      ),
      header: "batteries",
      delay: "1000",
      offset: offsetTwo,
    },
  ]

  const products = contents.map(content => {
    return (
      <div
        className="px-1 w-full lg:my-4 lg:px-4 md:w-1/2 lg:w-1/3 max-w-xs mx-3 my-3 h-full"
        key={content.id}
      >
        <div
          className="overflow-hidden rounded-lg shadow-lg flex flex-col items-center bg-white py-10 bg-gray-100"
          data-aos="fade-right"
          data-aos-delay={content.delay}
          data-aos-easing="ease-out-back"
          data-aos-offset={content.offset}
          data-aos-duration="500"
        >
          <div className="mb-5 h-48">{content.img}</div>
          <header className="flex items-center justify-between leading-tight p-2 md:p-4">
            <h3 className="text-lg font-semibold capitalize text-gray-700 ">
              {content.header}
            </h3>
          </header>
        </div>
      </div>
    )
  })

  return (
    <section className="body-font bg-white" id="products">
      <div className="container w-full max-w-7xl flex flex-col px-5 pt-28 pb-28 mx-auto">
        <div className="text-4xl text-blue-800 font-bold mb-14 text-center md:px-10 capitalize lg:text-4xl">
          <h2>Products We Cover</h2>
        </div>
        <div className="flex flex-wrap w-full mx-auto max-w-7xl justify-center">
          {products}
        </div>
      </div>
    </section>
  )
}

export default Products
