import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTools, faCar, faTruck } from "@fortawesome/free-solid-svg-icons"
import { StaticImage } from "gatsby-plugin-image"

const Services = () => {
  const services = [
    {
      id: 1,
      icon: <FontAwesomeIcon icon={faCar} size="3x" />,

      header: "Maintenance",
      alt: "maintenance-icon",
      delay: "0",
    },
    {
      id: 2,
      icon: <FontAwesomeIcon icon={faTools} size="3x" />,

      header: "Repair",
      alt: "Repair-icon",
      delay: "200",
    },
    {
      id: 3,
      icon: (
        <StaticImage
          src="../images/services/foam.svg"
          alt="foam"
          width={50}
          height={50}
        />
      ),
      header: "Foam Filling",
      alt: "Foam filling-icon",
      delay: "400",
    },
    {
      id: 4,
      icon: <FontAwesomeIcon icon={faTruck} size="3x" />,
      header: "Mobile Press On",
      alt: "Mobile Press On-icon",
      delay: "600",
    },
  ]
  return (
    <section className="body-font bg-section" id="services">
      <div className="container w-full max-w-5xl flex flex-col px-5 py-20 mx-auto items-center">
        <div className="text-3xl text-blue-800 font-bold mb-10 text-center md:mb-0 capitalize md:text-4xl lg:text-4xl w-full">
          <h2>services</h2>
        </div>
        <div className="flex flex-wrap items-center w-full md:mt-10">
          {services.map(service => {
            return (
              <div
                className="flex flex-col items-center w-1/2 md:w-1/4 my-5 text-blue-800"
                data-aos="fade-down"
                data-aos-duration="500"
                data-aos-delay={service.delay}
                key={service.id}
                data-aos-easing="ease-out-back"
              >
                {service.icon}
                <p className="text-base font-bold text-gray-800 mt-4">
                  {service.header}
                </p>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Services
