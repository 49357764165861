import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const About = () => {
  return (
    <section className="text-gray-600 body-font bg-section" id="about">
      <div className="container max-w-6xl mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-18 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1
            className="title-font sm:text-4xl md:text-5xl text-3xl mb-4 font-bold text-blue-800"
            data-aos="fade-right"
            data-aos-delay="400"
            data-aos-duration="500"
          >
            About Us
          </h1>
          <p
            className="mt-8 leading-loose max-w-md"
            data-aos="fade-right"
            data-aos-delay="500"
            data-aos-duration="500"
          >
            <span className="font-semibold">Al Yazidi Company</span> is one of the tire leader companies in Saudi Arabia, in the retail and wholesale sectors.
            We are proud to be one of the largest multi-brand retail chains, with our partners and our branches all over Saudi Arabia.
          </p>
          <p
            className="mt-8 leading-loose max-w-md"
            data-aos="fade-right"
            data-aos-delay="500"
            data-aos-duration="500"
          >
            <span className="font-semibold">
              For more than 40 years,
            </span>
            we have strived to provide our dear customers with the highest quality and widest range of products at the lowest possible prices.
            Our goal is to provide our loyal customers with ease of access and reduce overall transportation costs.
          </p>
        </div>
        <div
          className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6"
          data-aos="zoom-in"
          data-aos-easing="ease-out-back"
          data-aos-duration="500"
        >
          <StaticImage
            className="object-cover object-center rounded"
            alt="building"
            src="../images/building.jpg"
            width={650}
            height={650}
          />
        </div>
      </div>
    </section>
  )
}

export default About
