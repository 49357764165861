import React, { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from 'react-hook-form'
import axios from "axios"
import { ErrorMessage } from '@hookform/error-message';


const Contact = () => {

  const [captcha, setCaptcha] = useState("");
  const [message, setMessage] = useState("");


  const onChange = value => {
    setCaptcha(value);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors
  } = useForm();


  const onSubmit = async (data) => {
    try {
      const response = await axios.post("https://contacts-tau.vercel.app/api/alyazidi",
        {
          name: data.name,
          email: data.email,
          subject: data.subject,
          message: data.message,
          captcha: captcha,
        }
      )
      setMessage(response.data.message);
      setCaptcha("");
      reset();
      clearErrors();

    } catch (err) {
      setMessage(err.response.data.message);
      reset();
      clearErrors();
    }
  };




  return (
    <section className="text-gray-600 body-font relative" id="contact">
      <div className="container max-w-7xl w-full px-5 py-20 mx-auto">
        <div className="text-3xl text-blue-800 font-bold mb-10 text-center md:mb-0 capitalize md:text-4xl lg:text-4xl w-full">
          <h2>Contact Us</h2>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="lg:w-1/2 md:w-2/3 mx-auto mt-12">
            <div className="flex flex-wrap -m-2 justify-center">
              <div className="p-2 w-full sm:w-1/2">
                <div className="relative">
                  <label
                    htmlFor="name"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"

                    {...register('name', { required: "This is field is required", maxLength: 80 })}
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                  <div className="text-sm text-red-500">
                    <ErrorMessage errors={errors} name="name" />
                  </div>
                </div>

              </div>
              <div className="p-2 w-full sm:w-1/2">
                <div className="relative">
                  <label
                    htmlFor="email"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    {...register("email", { required: "This is field is required", pattern: /^\S+@\S+$/i })}

                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                  <div className="text-sm text-red-500">
                    <ErrorMessage errors={errors} name="email" />
                  </div>
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  <label
                    htmlFor="Subject"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Subject
                  </label>
                  <input
                    type="subject"
                    id="subject"
                    name="subject"
                    {...register('subject')}
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  <label
                    htmlFor="message"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    {...register('message', { required: "This is field is required" })}
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  ></textarea>
                  <div className="text-sm text-red-500">
                    <ErrorMessage errors={errors} name="message" />
                  </div>

                </div>
              </div>
              <div className="my-2">
                <ReCAPTCHA
                  sitekey={process.env.GOOGLE_RECAPTCHA}
                  onChange={onChange}
                />
              </div>
              <div className="p-2 w-full">
                <button className="flex mx-auto text-white bg-blue-800 border-0 py-2 px-8 focus:outline-none hover:bg-blue-900 rounded text-lg">
                  Submit
                </button>
              </div>
            </div>
          </div>
          {message.length > 0 || typeof (message) != undefined || message != null ? (<div className="pt-5 font-bold text-center capitalize">
            <p>{message}</p>
          </div>) :
            (<div></div>)}
        </form>
        <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
          <a href="mailto:sales@alyazidi.com" className="text-blue-700">
            sales@alyazidi.com
          </a>
        </div>
      </div>
    </section>
  )
}

export default Contact
