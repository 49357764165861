import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Services from "../components/services"
import Contact from "../components/contact"
import Products from "../components/product"
import About from "../components/about"
import { useInView } from "react-intersection-observer"

const IndexPage = () => {
  const [ref, inView] = useInView({
    rootMargin: "0px 0px -200px 0px",
  })

  return (
    <Layout inView={inView ?? false}>
      <SEO title="Home" />
      <Hero />
      <div ref={ref}>
        <Services />
        <Products />
        <About />
        <Contact />
      </div>
    </Layout>
  )
}
export default IndexPage
